<template>
  <ResourcesMobileGroup1 :title="group1" />
  <ResourcesMobileGroup2 :title="group2" />
</template>
<script>
import ResourcesMobileGroup1 from "@/components/menu/Resources/ResourcesMobileGroup1.vue";
import ResourcesMobileGroup2 from "@/components/menu/Resources/ResourcesMobileGroup2.vue";
export default {
  components: {
    ResourcesMobileGroup1,
    ResourcesMobileGroup2,
  },
  props: {
    group1: {
      type: String,
    },
    group2: {
      type: String,
    },
  },
};
</script>

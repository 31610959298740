<template>
  <ShowCasesMobileGroup1 :title="group1" />
  <ShowCasesMobileGroup2 :title="group2" />
</template>
<script>
import ShowCasesMobileGroup1 from "@/components/menu/ShowCases/ShowCasesMobileGroup1.vue";
import ShowCasesMobileGroup2 from "@/components/menu/ShowCases/ShowCasesMobileGroup2.vue";
export default {
  components: {
    ShowCasesMobileGroup1,
    ShowCasesMobileGroup2,
  },
  props: {
    group1: {
      type: String,
    },
    group2: {
      type: String,
    },
  },
};
</script>

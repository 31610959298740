<template>
  <div class="col-md-6 col-sm-6 col-12" style="padding: 5px 15px">
    <p class="title">{{ title }}</p>
    <div class="subtitle" v-for="(ShowCase, key) in ShowCases" :key="key">
      <h3 class="submenuHeader">{{ ShowCase.title }}</h3>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      lang: "en",
      ShowCases: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/menu_|_show_case/collections/group_21/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.ShowCases = [];
            return;
          } else if (data.data.length == 0) {
            this.ShowCases = [];
            return;
          }
          this.ShowCases = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 500;
}
h3.submenuHeader {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}
h3.submenuHeader:hover {
  background-color: #f1f1f1;
  color: #0b1320;
  text-decoration: none;
}
</style>

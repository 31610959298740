<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.96 10.24L35.68 9.74C35.3188 9.13538 34.8094 8.63286 34.2 8.28L20.78 0.54C20.1724 0.1875 19.4826 0.00124 18.78 0H18.2C17.4974 0.00124 16.8076 0.1875 16.2 0.54L2.78 8.3C2.17394 8.65052 1.67052 9.15394 1.32 9.76L1.04 10.26C0.6875 10.8677 0.50124 11.5575 0.5 12.26V27.76C0.50124 28.4626 0.6875 29.1524 1.04 29.76L1.32 30.26C1.67958 30.859 2.18098 31.3604 2.78 31.72L16.22 39.46C16.8246 39.8198 17.5164 40.0066 18.22 40H18.78C19.4826 39.9988 20.1724 39.8126 20.78 39.46L34.2 31.7C34.812 31.3574 35.3174 30.852 35.66 30.24L35.96 29.74C36.3082 29.1306 36.4942 28.442 36.5 27.74V12.24C36.4988 11.5375 36.3126 10.8477 35.96 10.24ZM18.2 4H18.78L30.5 10.76L18.5 17.68L6.5 10.76L18.2 4ZM20.5 35L32.2 28.24L32.5 27.74V14.22L20.5 21.16V35Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>

<template>
  <div class="col-md-4 p-4 responsivedevice" style="background: #f4f4f4">
    <p>Featured from Blog</p>
    <div
      class="row"
      v-for="resourcesBlog in resourcesBlogs"
      :key="resourcesBlog"
    >
      <div class="col-md-3 col-sm-3 col-3 pt-2">
        <Image :fpath="`${resourcesBlog.image}.jpg`" class="serviceimage" />
      </div>
      <div class="col-md-9 col-sm-9 col-9 pt-2">
        <h3 class="blogHeader">{{ resourcesBlog.title }}</h3>
        <p class="blogContent">
          {{ resourcesBlog.description.slice(0, 50) }}...
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Image from "@/components/Image.vue";
import axios from "axios";
export default {
  components: {
    Image,
  },
  data() {
    return {
      lang: "en",
      resourcesBlogs: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/menu_|_resources/collections/resources_blogs/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.resourcesBlogs = [];
            return;
          } else if (data.data.length == 0) {
            this.resourcesBlogs = [];
            return;
          }
          this.resourcesBlogs = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<template>
  <ResourcesGroup1 :title="group1" />
  <ResourcesGroup2 :title="group2" />
</template>
<script>
import ResourcesGroup1 from "@/components/menu/Resources/ResourcesGroup1.vue";
import ResourcesGroup2 from "@/components/menu/Resources/ResourcesGroup2.vue";
export default {
  components: {
    ResourcesGroup1,
    ResourcesGroup2,
  },
  props: {
    group1: {
      type: String,
    },
    group2: {
      type: String,
    },
  },
};
</script>

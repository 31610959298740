<template>
  <ShowCasesGroup1 :title="group1" />
  <ShowCasesGroup2 :title="group2" />
</template>
<script>
import ShowCasesGroup1 from "@/components/menu/ShowCases/ShowCasesGroup1.vue";
import ShowCasesGroup2 from "@/components/menu/ShowCases/ShowCasesGroup2.vue";
export default {
  components: {
    ShowCasesGroup1,
    ShowCasesGroup2,
  },
  props: {
    group1: {
      type: String,
    },
    group2: {
      type: String,
    },
  },
};
</script>
